import styled from 'styled-components';

import { Color } from '@/core/interfaces/common';

import { backgroundVariant } from '@/utils/backgroundVariant';

import { breakpoint } from '@/themes/breakpoints';

import {
  BodyLarge,
  H1,
} from '../Typography/Typography.styles';

export const ContactBackgroundWrapper = styled.div<{ $color: Color }>`
  ${backgroundVariant}
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-image: ${({ $color }) => `url("/assets/images/vectorBackgrounds/${$color}.svg")`};
  background-position-x: center;
  background-position-y: center;
  text-align: center;
`;

export const ContactWrapperHeading = styled.h1`
  ${H1}
  margin: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.black};
`;

export const ContactWrapperParagraph = styled.p`
  ${BodyLarge}
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral[700]};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  @media ${breakpoint.md} {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;
