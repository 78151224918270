import Link from 'next/link';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { errorMessages } from '@/core/messages/error.messages';
import { VectorBanner } from '@/core/components/VectorBanner/VectorBanner';
import {
  Button,
  ButtonVariant,
} from '@/core/components/Button';
import { routePaths } from '@/core/constants/routes';
import { Color } from '@/core/interfaces/common';

import {
  ButtonsWrapper,
  ContentWrapper,
  Sentence,
} from '../Errors.styles';

export const Custom404 = () => {
  const { formatMessage } = useIntl();
  const router = useRouter();

  return (
    <>
      <VectorBanner
        header={formatMessage(errorMessages.headingError404)}
        subheader={formatMessage(errorMessages.subheadingError404)}
        color={Color.GREY}
      />
      <ContentWrapper>
        <Sentence>{formatMessage(errorMessages.infoError404)}</Sentence>
        <ButtonsWrapper>
          <Button
            variant={ButtonVariant.PRIMARY}
            renderAs={Link}
            label={formatMessage(errorMessages.homepageButtonError404)}
            href={routePaths.home}
          />
          <Button
            variant={ButtonVariant.SECONDARY}
            label={formatMessage(errorMessages.reloadButtonError404)}
            onClick={() => router.back()}
          />
        </ButtonsWrapper>
      </ContentWrapper>
    </>
  );
};
