import { defineMessages } from 'react-intl';

export const errorMessages = defineMessages({
  buttonError500: {
    defaultMessage: 'Reload page',
    description: 'Button 500 error',
    id: 'error.internalServerError.button',
  },
  headingError404: {
    defaultMessage: 'Something went wrong',
    description: 'Heading 404 error',
    id: 'error.pageNotFound.heading',
  },
  headingError500: {
    defaultMessage: '500 error',
    description: 'Heading 500 error',
    id: 'error.internalServerError.heading',
  },
  homepageButtonError404: {
    defaultMessage: 'Go to homepage',
    description: 'Homepage Button 404 error',
    id: 'error.pageNotFound.homepageButton',
  },
  infoError404: {
    defaultMessage: "The page you are looking for doesn't exist or server was unable to complete your request.",
    description: 'Info 404 error',
    id: 'error.pageNotFound.info',
  },
  infoError500: {
    defaultMessage: 'Something went wrong.',
    description: 'Info 500 error',
    id: 'error.internalServerError.info',
  },
  messageError500: {
    defaultMessage: 'The server encountered an internal error or misconfiguration and was unable to complete your request.',
    description: 'Message 500 error',
    id: 'error.internalServerError.message',
  },
  reloadButtonError404: {
    defaultMessage: 'Reload',
    description: 'Reload Button 404 error',
    id: 'error.pageNotFound.reloadButton',
  },
  subheadingError404: {
    defaultMessage: 'Error occurred',
    description: 'Subheading 404 error',
    id: 'error.pageNotFound.subheading',
  },
  subheadingError500: {
    defaultMessage: 'Internal server error',
    description: 'Subheading 500 error',
    id: 'error.internalServerError.subheading',
  },
});
