import React from 'react';

import { Color } from '@/core/interfaces/common';

import { HeaderWrapper } from '../HeaderWrapper';
import {
  ContactBackgroundWrapper,
  ContactWrapperHeading,
  ContactWrapperParagraph,
} from './VectorBanner.styles';

type Props = {
  header: string;
  subheader: string;
  color: Color;
};

export const VectorBanner = ({
  header,
  subheader,
  color = Color.YELLOW,
}: Props) => (
  <HeaderWrapper>
    <ContactBackgroundWrapper $color={color}>
      <ContactWrapperHeading>{header}</ContactWrapperHeading>
      <ContactWrapperParagraph>{subheader}</ContactWrapperParagraph>
    </ContactBackgroundWrapper>
  </HeaderWrapper>
);
