import styled from 'styled-components';

import { breakpoint } from '@/themes/breakpoints';

import { MarginWrapper } from '../MarginWrapper';
import { BodyMedium } from '../Typography';

export const ContentWrapper = styled(MarginWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 150px;
  text-align: center;

  @media ${breakpoint.md} {
    padding: 80px 0 200px;
  }
`;

export const Sentence = styled.div`
  ${BodyMedium}
  padding-bottom: 30px;
  color: ${({ theme: { colors } }) => (colors.neutral[700])};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  gap: 24px;

  @media ${breakpoint.sm} {
    flex-direction: row-reverse;
    justify-content: center;
  }
`;
